import React from 'react';

import * as styles from './Geschichte.module.css';
import { GeschichteEntry } from './GeschichteEntry';

export const Geschichte = ({ data }) => {
  return (
    <section className={'defaultWidth'}>
      {data.map(({ node }) => (
        <GeschichteEntry node={node} key={node.id} />
      ))}
    </section>
  );
};

export default Geschichte;
